import { i18nService } from './i18n.service'
import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common'
import { Injectable, inject } from '@angular/core'
import { isNumeric } from '@utils/is-numeric'

@Injectable({
	providedIn: 'root',
})
export class FormatService {
	currencyPipe = inject(CurrencyPipe)

	datePipe = inject(DatePipe)

	decimalPipe = inject(DecimalPipe)

	i18n = inject(i18nService)

	public formatCurrency(value: any, display = 'symbol-narrow', digits = '1.2') {
		try {
			return this.currencyPipe.transform(value, '', display, digits)
		} catch (error) {
			console.error('Error formatting currency', value)
			return value
		}
	}

	public formatDate(value: any, format = this.i18n.dateFormat) {
		try {
			return this.datePipe.transform(value, format ?? 'shortDate')
		} catch (error) {
			console.error('Error formatting date', value)
			return value
		}
	}

	public formatNumber(value: any, digits = '1.0-2') {
		try {
			const str = this.decimalPipe.transform(value, digits)
			return str && isNumeric(str) ? parseFloat(str) : str
		} catch (error) {
			console.error('Error formatting number', value)
			return value
		}
	}

	public formatPercent(value: any, digits = '1.0-2') {
		try {
			return this.decimalPipe.transform(value, digits) + '%'
		} catch (error) {
			console.error('Error formatting percent', value)
			return value
		}
	}

	public format(value: any, format?: string) {
		switch (format) {
			case 'currency':
				return this.formatCurrency(value)

			case 'date':
				return this.formatDate(value)

			case 'number':
				return this.formatNumber(value)

			case 'percent':
				return this.formatPercent(value)

			default:
				return value
		}
	}
}
